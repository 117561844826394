<template>
	<div id="set-bet-options">
		<div class="bet-options-container">
			<div v-if="!!serverBusy" id="bet-options-loading">
				<span class="loading-icon"></span>
				<span class="loading-message">{{ busyText }}</span>
			</div>
			<fieldset>
				<legend>Set Game Options</legend>
				<h2>{{ casinoGame.gameTitle }}</h2>
				<h3>RTP per Denom</h3>
				<p class="yellow-text" style="padding: 0 15px; text-align: left" v-if="linesOptions[0] !== 0">
					Note: The RTP selections here refer to the paytable Return-To-Player. The total RTP is the sum of the paytable RTP plus all
					progressive contributions. A total greater than 100% will result in a loss for the casino.
				</p>
				<div id="rtp-per-denom">
					<div v-for="(denom, index) in casinoGame.gameConfigOptions.denominationsAU" :key="index">
						<label :for="`denom${denom}`">{{ systemCurrencyTool.formatFromAU(denom, minorWholeOrFull) }}</label>
						<select :title="`Select RTP for ${systemCurrencyTool.formatFromAU(denom, minorWholeOrFull)}`" :id="`denom${denom}`" v-model="setRtps[index]">
							<option v-for="(rtp, i) in rtpOptions" :key="i" :value="rtp">
								{{ rtp }}
							</option>
						</select>
					</div>
				</div>
				<div id="check-boxes">
					<div id="lines-container" v-if="linesOptions[0] !== 0">
						<h3>#Lines</h3>
						<div v-for="(line, index) in linesOptions" :key="index">
							<label :for="'Line' + line" :title="'Line ' + line">{{ line }}</label>
							<input
								type="checkbox"
								:id="'Line' + line"
								:title="'Line ' + line"
								:value="line"
								:checked="casinoGame.enabledNumberOfLines.length > 0 ? casinoGame.enabledNumberOfLines.includes(line) : false"
								v-model="lines"
							/>
						</div>
					</div>
					<div id="lines-container" class="ways" v-else>
						<h3>Ways Game</h3>
					</div>
					<div id="bets-container">
						<h3>Bets Per Line</h3>
						<div v-for="(bet, index) in betOptions" :key="index">
							<label :for="'Bet' + bet" :title="'Bet ' + bet">{{ bet }}</label>
							<input
								type="checkbox"
								:id="'Bet' + bet"
								:title="'Bet ' + bet"
								:value="bet"
								:checked="casinoGame.enabledBaseBets.length > 0 ? casinoGame.enabledBaseBets.includes(bet) : false"
								v-model="bets"
							/>
						</div>
					</div>
				</div>
				<div class="button-group">
					<button type="button" class="btn" v-if="casinoGame.gameDisabled" @click="saveGameOptions(true)" title="Re-enable This Game">
						Enable This Game
					</button>
					<button
						type="button"
						class="btn"
						v-if="!casinoGame.gameDisabled && fieldsChanged"
						@click="saveGameOptions()"
						title="Save Game Options"
					>
						Save Game Options
					</button>
					<button type="button" class="btn" @click="closeModal()" :title="closeButtonText">{{ closeButtonText }}</button>
				</div>
				<p v-if="casinoGame.gameDisabled">
					Default settings loaded for this disabled game. You can edit these settings before re-enabling game.
				</p>
			</fieldset>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "SetGameOptions",
	inheritAttrs: false,
	props: {
		adminState: Object,
		casinoGame: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			minorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			status: Object.assign({}, this.globalStatus),
			body: document.querySelector("body"),
			busyText: "",
			closeButtonText: "Close",
			serverBusy: false,
			fieldsChanged: false,
			rtpOptions: Array.from(this.casinoGame.gameConfigOptions.rtps),
			linesOptions: Array.from(this.casinoGame.gameConfigOptions.numberOfLines),
			betOptions: Array.from(this.casinoGame.gameConfigOptions.baseBets),
			setRtps: [],
			lines: [],
			bets: [],
			simpleConfigSaveData: [],
		};
	},
	watch: {
		setRtps: {
			handler() {
				this.fieldsChanged = true;
			},
			deep: true,
		},
		lines() {
			this.fieldsChanged = true;
		},
		bets() {
			this.fieldsChanged = true;
		},
		fieldsChanged() {
			this.closeButtonText = this.fieldsChanged ? "Cancel" : "Close";
		},
	},
	created() {
		let RTPDefaults = this.getDefaultRtps(this.casinoGame);
		this.rtpOptions.unshift(0);

		this.setRtps = this.casinoGame.gameDisabled ? RTPDefaults : Array.from(this.casinoGame.rtps);
		this.lines = this.casinoGame.gameDisabled ? this.linesOptions : this.casinoGame.enabledNumberOfLines;
		this.bets = this.casinoGame.gameDisabled ? this.betOptions : this.casinoGame.enabledBaseBets;

		this.fieldsChanged = false;

		let thisInstance = this;
		onBeforeUnmount(() => {
			thisInstance.body.onkeydown = null;
		});
	},
	mounted() {
		let thisInstance = this;
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") thisInstance.closeModal();
		};
	},
	methods: {
		async saveGameOptions(reenabling = false) {
			this.busyText = "Saving Game Options";
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			if (this?.lines?.length > 0) this.lines.sort((a, b) => (a > b ? 1 : -1));
			if (this?.bets?.length > 0) this.bets.sort((a, b) => (a > b ? 1 : -1));

			let thisInstance = this;
			this.casinoGame.denominationsAU.forEach((denomAU, i) => {
				let denom = {
					casinoId: thisInstance.casinoGame.casinoId,
					gameTitle: thisInstance.casinoGame.gameTitle,
					denomAU: denomAU,
					rtp: thisInstance.setRtps[i],
					enabledNumberOfLines: thisInstance.lines,
					enabledBaseBets: thisInstance.bets,
					isSimpleConfig: thisInstance.casinoGame.isSimpleConfig,
				};
				thisInstance.simpleConfigSaveData.push(denom);
			});

			try {
				let saveStatus = await sharedScripts.saveSimpleConfig(this);
				if (saveStatus.hasOwnProperty("ok") && !saveStatus.ok) {
					this.busyText = "";
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", saveStatus);
					this.closeModal();
					return false;
				}

				this.casinoGame.rtps = this.setRtps;
				this.casinoGame.enabledBaseBets = this.bets;
				this.casinoGame.enabledNumberOfLines = this.lines;
				let rtpsZeroed = this.casinoGame.rtps.every((a) => a === thisInstance.setRtps[0] && a === 0);
				this.casinoGame.gameDisabled = rtpsZeroed || this.lines.length <= 0 || this.bets.length <= 0;

				let successMessage = reenabling
					? `${this.casinoGame.gameTitle} re-enabled and Bet Options saved successfully`
					: `Bet Options saved successfully for ${this.casinoGame.gameTitle}`;

				this.status.message = saveStatus.ok ? successMessage : saveStatus.message;
				this.status.ok = saveStatus.ok;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateCurrentGameTableData", this.casinoGame);
				this.eventBus.emit("updateCasinoList");
				this.busyText = "";
				this.serverBusy = false;
				this.closeModal();
			} catch (e) {
				console.error(e);
				this.status.message = "Something went wrong saving Game Options";
				this.status.ok = false;
				this.status.userMustDismiss = true;
				this.busyText = "";
				this.serverBusy = false;
				this.eventBus.emit("updateStatus", this.status);
				this.closeModal();
			}
		},
		closeModal() {
			this.eventBus.emit("closeGameConfigurationModal");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#bet-options-loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	background-color: rgb(0 0 0 / 75%);
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
	text-align: center;
	align-content: center;
}

#set-bet-options {
	position: fixed;
	width: 100vw;
	height: calc(100vh - 146px);
	/* display: grid;
	align-items: center; */
	top: 80px;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgb(0 0 0 / 75%);
	color: #fff;
	backdrop-filter: blur(5px);
	z-index: 100;
	overflow: hidden;
}

.bet-options-container {
	overflow: hidden auto;
	height: 90%;
	width: 50%;
	margin: 15px auto;
	min-width: 20em;
	background: #222;
	padding: 15px;
	border-radius: 12px;
	text-align: center;
	overflow: hidden auto;
	padding-bottom: 60px;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	.bet-options-container {
		height: 75%;
	}

	.bet-options-container fieldset {
		padding-bottom: 50px;
	}
}

#check-boxes {
	display: flex;
	justify-content: space-around;
}

.button-group {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	margin-top: 10px;
}

#lines-container > div,
#bets-container > div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 2em;
	user-select: none;
}

#rtp-per-denom {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

#rtp-per-denom > div {
	display: flex;
	flex-flow: column nowrap;
}

#lines-container.ways {
	align-items: center;
	display: flex;
}

.ways {
	text-transform: uppercase;
	text-align: center;
}

@media (min-width: 768px) {
	#set-bet-options {
		height: calc(100vh - 160px);
		top: 98px;
		overflow: hidden auto;
	}

	.bet-options-container {
		height: auto;
		padding-bottom: 15px;
	}
}

@media (max-width: 1023px) {
	#set-bet-options {
		display: flex;
	}

	.bet-options-container {
		width: auto;
		margin: auto;
	}

	#check-boxes {
		margin-top: 30px;
	}

	fieldset {
		padding: 0;
	}

	input[type="checkbox"] {
		height: 40px;
		width: 40px;
	}
}
</style>
