<template>
	<div id="game-config">
		<div v-if="serverBusy" id="game-admin-loading">
			<span class="loading-icon"></span>
			<span class="loading-message outline-black">{{ busyText }}</span>
		</div>
		<h2>Casino Game Configuration</h2>
		<div class="btn-container">
			<button class="refresh btn" title="Refresh Game Table Data" @click="refreshTable()">Refresh</button>
			<button v-if="!isMobile && adminState?.isSysadmin" class="bulk-rtp btn" title="Bulk RTP Assignment" @click="bulkRtpAssignment()">
				Bulk RTP Assignment
			</button>
		</div>
		<div class="game-config">
			<table class="game-config-list" v-if="!isMobile">
				<thead>
					<tr class="sticky">
						<th class="data-header title" rowspan="2">Title</th>
						<th class="data-header paytable" colspan="5">Paytable RTP</th>
						<th class="data-header bets" colspan="2">Bet Options</th>
						<th class="data-header progressives" colspan="3">Progressives</th>
					</tr>
					<tr class="sticky tier-sticky">
						<th class="data-header paytable">{{ systemCurrencyTool.formatFromAU(1, minorWholeOrFull) }}</th>
						<th class="data-header paytable">{{ systemCurrencyTool.formatFromAU(2, minorWholeOrFull) }}</th>
						<th class="data-header paytable">{{ systemCurrencyTool.formatFromAU(5, minorWholeOrFull) }}</th>
						<th class="data-header paytable">{{ systemCurrencyTool.formatFromAU(10, minorWholeOrFull) }}</th>
						<th class="data-header paytable">{{ systemCurrencyTool.formatFromAU(25, minorWholeOrFull) }}</th>
						<th class="data-header bets"># Lines</th>
						<th class="data-header bets">Bet Per Line</th>
						<th class="data-header progressives">Family</th>
						<th class="data-header progressives">Contributions %</th>
						<th class="data-header progressives">Total %</th>
					</tr>
				</thead>
				<tbody v-if="currentGameTableData.length > 0">
					<ConfigGameInstance
						v-for="(game, index) in currentGameTableData"
						:key="index"
						:game="game"
						:gameConfigOptionsList="gameConfigOptionsList"
						:adminState="adminState"
						:systemCurrencyTool="systemCurrencyTool"
					/>
				</tbody>
			</table>
			<div v-else>
				<ConfigGameInstanceMobile
					v-for="(game, index) in currentGameTableData"
					:key="index"
					:game="game"
					:gameConfigOptionsList="gameConfigOptionsList"
					:adminState="adminState"
					:systemCurrencyTool="systemCurrencyTool"
				/>
			</div>
			<h2 v-if="gameConfigOptionsList.length > 0 && !(currentGameTableData.length > 0)">No Games Configured</h2>
		</div>
	</div>
	<transition name="fade">
		<component
			v-bind:is="currentModal"
			:gameConfigOptionsList="gameConfigOptionsList"
			:adminState="adminState"
			:casinoPotFamilyNames="casinoPotFamilyNames"
			:casinoGame="casinoGame"
			:progressivesSimpleList="progressivesSimpleList"
			:currentGamesSettingsList="currentGamesSettingsList"
			:currentGameTableData="currentGameTableData"
			:systemCurrencyTool="systemCurrencyTool"
		/>
	</transition>
</template>

<script>
import store from "@/store/index";
import router from "@/router";
import sharedScripts from "@/dependencies/sharedScripts";	
import { onBeforeUnmount } from "vue";
import SetContributionFraction from "@/components/SetContributionFraction";
import SetGameOptions from "@/components/SetGameOptions";
import ConfigGameInstance from "@/components/ConfigGameInstance";
import ConfigGameInstanceMobile from "@/components/ConfigGameInstanceMobile";
import BulkRTPAssignment from "@/components/BulkRTPAssignment";

export default {
	name: "CasinoGameConfiguration",
	inheritAttrs: false,
	components: {
		SetContributionFraction,
		SetGameOptions,
		ConfigGameInstance,
		ConfigGameInstanceMobile,
		BulkRTPAssignment,
	},
	props: {
		adminState: Object,
		isMobile: Boolean,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			minorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			currentModal: null,
			casinoGame: null,
			gameConfigOptionsList: [],
			casinoPotFamilyNames: [],
			casinoGames: [],
			currentGamesSettingsList: [],
			progressivesSimpleList: [],
			currentGameTableData: [],
			potFamiliesTiers: [],
		};
	},
	watch: {},
	created() {
		let thisInstance = this;
		this.eventBus.on("closeGameConfigurationModal", () => {
			thisInstance.currentModal = null;
			thisInstance.stopBodyScroll(false);
		});
		this.eventBus.on("setGameConfigurationModal", (payload) => {
			thisInstance.currentModal = payload.currentModal;
			thisInstance.casinoGame = payload.game;
			thisInstance.stopBodyScroll(true);
		});
		this.eventBus.on("updateCurrentGameTableData", (payload) => {
			thisInstance.currentGameTableData = thisInstance.currentGameTableData.filter((game) => {
				return game.gameTitle !== payload.gameTitle;
			});
			thisInstance.currentGameTableData.push(payload);
			thisInstance.currentGameTableData.sort((a, b) => (a.gameTitle > b.gameTitle ? 1 : -1));
			store.dispatch("setCurrentGameTableData", thisInstance.currentGameTableData);
		});
		this.eventBus.on("refreshTable", () => {
			this.currentGameTableData = [];
			this.getGameConfigOptions(true);
		});
		onBeforeUnmount(() => {
			thisInstance.eventBus.off("updateCurrentGameTableData");
			thisInstance.eventBus.off("refreshTable");
			thisInstance.eventBus.off("closeGameConfigurationModal");
			thisInstance.eventBus.off("setGameConfigurationModal");
		});
	},
	mounted() {
		this.getGameConfigOptions();
	},
	methods: {
		refreshTable() {
			this.eventBus.emit("refreshTable");
		},
		bulkRtpAssignment() {
			this.currentModal = "BulkRTPAssignment";
			this.stopBodyScroll(true);
		},
		async getGameConfigOptions(refresh = false) {
			this.busyText = "Loading Game Config Options";
			this.serverBusy = true;

			// check if currentGameTableData was cached within the last hour and get that instead of server calls
			// also get cached casinoPotFamilyNames and gameConfigOptionsList
			let currentTime = new Date().getTime();
			if (!refresh && store.state?.currentGameTableData.length > 0 && currentTime < this.adminState?.currentGameTableDataExpire) {
				this.currentGameTableData = store.state.currentGameTableData;
				this.casinoPotFamilyNames = store.state.casinoPotFamilyNames;
				this.gameConfigOptionsList = store.state.gameConfigOptionsList;
				this.progressivesSimpleList = store.state.progressivesSimpleList;
				this.status.message = "Game Current Configuration Data fetched from cache";
				this.status.ok = true;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				return false;
			}

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			if (this.gameConfigOptionsList?.length > 0) {
				// remove the zero rtp option that gets added for disabling denoms at getCurrentGamesSettings()
				this.gameConfigOptionsList.forEach((game) => {
					if (game.rtps.length > 5) game.rtps.shift();
				});
				this.status.message = "Game Configuration Options fetched from cache";
				this.status.ok = true;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
				this.getGameConfigurationSettings();
				return;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/admin/game/options", this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;
			params.set("casinoId", this.adminState.casinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.gameConfigOptionsList = dataJson;

				store.dispatch("setGameConfigOptionsList", this.gameConfigOptionsList);
				this.serverBusy = false;
				this.busyText = "";
				this.getGameConfigurationSettings();
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				router.push("/");
				return false;
			}
		},
		async getGameConfigurationSettings() {
			this.busyText = "Loading Progressive Pots";
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/admin/progressives/pots", this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			params.set("limit", 1000);
			params.set("casinoId", this.adminState.casinoId);
			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.progressivesSimpleList = dataJson;
				store.dispatch("setProgressivesSimpleList", this.progressivesSimpleList);

				let thisInstance = this;
				this.casinoPotFamilyNames = [];
				const mapNames = new Map();
				for (const item of dataJson) {
					if (!mapNames.has(item.potFamilyName)) {
						mapNames.set(item.potFamilyName, true);
						thisInstance.casinoPotFamilyNames.push(item.potFamilyName);
					}
				}

				store.dispatch("setPotFamilyNames", this.casinoPotFamilyNames);
				this.serverBusy = false;
				this.busyText = "";
				this.getCurrentGamesSettings();
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				router.push("/");
				return false;
			}
		},
		makeDenomObjectArrays(gameOptionsDenoms, gameByTitle) {
			let rtps = [];
			let denomAU = [];
			// let enabledBaseBets = [];
			// let enabledNumberOfLines = [];
			let returnObject = {};
			if (gameOptionsDenoms.gameDisabled) {
				returnObject.rtps = gameOptionsDenoms.rtps;
				returnObject.enabledBaseBets = gameOptionsDenoms.baseBets;
				returnObject.enabledNumberOfLines = gameOptionsDenoms.numberOfLines;
				returnObject.gameTitle = gameOptionsDenoms.gameTitle;
				returnObject.isSimpleConfig = true;
				returnObject.gameDisabled = gameOptionsDenoms.gameDisabled;
				returnObject.denomAU = gameOptionsDenoms.denominationsAU;
				return returnObject;
			}

			gameOptionsDenoms.denominationsAU.forEach((denom) => {
				let currentGameDenom = gameByTitle.filter((game) => game.denomAU === denom);
				if (!currentGameDenom.length > 0) {
					rtps.push(0);
					returnObject.enabledBaseBets = gameByTitle[0].enabledBaseBets;
					returnObject.enabledNumberOfLines = gameByTitle[0].enabledNumberOfLines;
					returnObject.gameTitle = gameByTitle[0].gameTitle;
					returnObject.isSimpleConfig = gameByTitle[0].isSimpleConfig;
				} else {
					rtps.push(currentGameDenom[0].rtp);
					returnObject.enabledBaseBets = currentGameDenom[0].enabledBaseBets;
					returnObject.enabledNumberOfLines = currentGameDenom[0].enabledNumberOfLines;
					returnObject.gameTitle = currentGameDenom[0].gameTitle;
					returnObject.isSimpleConfig = currentGameDenom[0].isSimpleConfig;
				}
				denomAU.push(denom);
			});
			returnObject.denomAU = denomAU;
			returnObject.rtps = [...rtps];
			return returnObject;
		},
		async getCurrentGamesSettings() {
			this.busyText = "Loading Game Settings";
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/admin/game/simpleconfig", this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;
			params.set("casinoId", this.adminState.casinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let thisInstance = this;
				let dataJson = await response.json();

				this.gameConfigOptionsList.forEach((gameOptions) => {
					let newGameItem = new Object();
					let gameByTitle = dataJson.filter((game) => game.gameTitle === gameOptions.gameTitle);
					gameOptions.gameDisabled = !gameByTitle.length > 0;
					let gameArrays = thisInstance.makeDenomObjectArrays(gameOptions, gameByTitle);

					newGameItem.gameDisabled = gameOptions.gameDisabled;
					newGameItem.gameConfigOptions = gameOptions;
					newGameItem.casinoId = this.adminState.casinoId;
					newGameItem.gameTitle = gameArrays.gameTitle;
					newGameItem.isSimpleConfig = gameArrays.isSimpleConfig;
					newGameItem.rtps = Array.from(gameArrays.rtps);
					newGameItem.denominationsAU = Array.from(gameArrays.denomAU);
					newGameItem.enabledBaseBets = Array.from(gameArrays.enabledBaseBets);
					newGameItem.enabledNumberOfLines = Array.from(gameArrays.enabledNumberOfLines);
					thisInstance.currentGameTableData.push(newGameItem);
				});

				const mapNames = new Map();
				let newGameArray = [];
				for (const game of thisInstance.currentGameTableData) {
					if (!mapNames.has(game.gameTitle)) {
						mapNames.set(game.gameTitle, true);
						newGameArray.push(game);
					}
				}
				this.currentGameTableData = newGameArray;
				this.currentGamesSettingsList = dataJson;
				this.serverBusy = false;
				this.busyText = "";
				this.mapPotFamiliesToGames();
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				router.push("/");
				return false;
			}
		},
		async mapPotFamiliesToGames() {
			this.busyText = "Mapping Pot Families and Contributions to Games";
			this.serverBusy = true;

			let thisInstance = this;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/admin/progressives/simpleconfig", this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;
			params.set("casinoId", this.adminState.casinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();
				let newGameList = [];

				if ((await dataJson.length) > 0) {
					this.casinoGames = dataJson;
					store.dispatch("setCasinoGames", this.casinoGames);

					this.currentGameTableData.forEach((item) => {
						let potFamiliesTiers = [];
						let thisItem = item;
						let totalPercent = 0;
						let familiyMatchesForGame = dataJson.filter((fam) => fam.gameTitle === thisItem.gameTitle);
						if (!thisItem.hasOwnProperty("gameConfigOptions")) {
							let gameConfigOptions = thisInstance.gameConfigOptionsList.filter((config) => config.gameTitle === thisItem.gameTitle)[0];
							thisItem.gameConfigOptions = Object.assign({}, gameConfigOptions);
						}
						familiyMatchesForGame.forEach((family) => {
							potFamiliesTiers.push({
								tier: family.tier,
								contributionFraction: family.contributionFraction,
								potFamilyName: family.potFamilyName,
							});
						});
						thisItem.potFamiliesTiers = potFamiliesTiers;
						thisItem.potFamilyName = familiyMatchesForGame[0]?.potFamilyName || "";
						// thisItem.gameDisabled = thisItem?.gameDisabled || false;
						newGameList.push(thisItem);
					});
				} else {
					this.status.message = "No progressives were returned by the server";
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
				}

				newGameList.sort((a, b) => (a.gameTitle > b.gameTitle ? 1 : -1));

				this.currentGameTableData = newGameList;
				store.dispatch("setCurrentGameTableData", this.currentGameTableData);

				// set a currentGameTableData cache expiration of 1 hour.
				let STATE = this.adminState;
				let timecode = new Date().getTime();
				STATE.currentGameTableDataExpire = timecode + 3600000;
				this.eventBus.emit("updateAdminState", STATE);
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				router.push("/");
				return false;
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sticky {
	top: -16px;
	color: #000;
}

.tier-sticky {
	top: 13px;
}

.game-config-list {
	width: 100%;
}

.game-config-list {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
}

.game-config-list th,
.game-config-list td {
	border: 1px #000 solid;
	padding: 5px;
}

.game-config-list * {
	text-overflow: ellipsis;
}

@media (max-width: 1025px) {
	.game-config-list * {
		overflow-wrap: anywhere;
	}
}

.game-config-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.data-header {
	text-align: center;
}

.title {
	background-color: #000;
	color: #fff;
}

.paytable {
	background-color: #00a6ff;
}

.bets {
	background-color: #00ffbf;
}

.progressives {
	background-color: #ffa000;
}

.edit-contributions,
.edit-lines {
	cursor: pointer;
	transition: background-color 0.2s;
}

.edit-contributions:hover,
.edit-lines:hover {
	background-color: #ff0;
}

#game-config {
	position: relative;
}

.btn-container {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	position: absolute;
	top: 0;
	width: 100%;
	margin: auto;
}

.refresh,
.bulk-rtp {
	margin: 0;
	padding: 5px 10px;
}

#game-admin-loading {
	position: fixed;
	display: grid;
	align-content: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	color: #fff;
	background: rgb(0 0 0 / 80%);
	backdrop-filter: blur(5px);
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
}

.loading-message {
	margin: 15px;
}

@media (max-width: 767px) {
	.btn-container {
		top: -30px;
		justify-content: flex-end;
	}
}
</style>
