<template>
	<div id="bulk-set-bet-options">
		<div class="bulk-bet-options-container">
			<div v-if="serverBusy" id="bulk-bet-options-loading">
				<span class="loading-icon"></span>
				<span class="loading-message">{{ busyText }}</span>
			</div>
			<fieldset>
				<legend>Bulk RTP Assignment</legend>
				<h4>RTP per Denom</h4>
				<div id="rtp-per-denom">
					<div v-for="(denom, index) in denoms" :key="index">
						<label :for="`denom${denom}`">{{ systemCurrencyTool.formatFromAU(denom, minorWholeOrFull) }}</label>
						<select
							:title="`Select RTP for ${systemCurrencyTool.formatFromAU(denom, minorWholeOrFull)}`"
							:id="`denom${denom}`"
							v-model="rtpsByDenom[index]"
						>
							<option v-for="(rtp, i) in rtpOptions" :key="i" :value="{ [denom]: rtp.value }">{{ rtp.text }}</option>
						</select>
					</div>
				</div>
				<p>
					<small>NOTE: Bulk setting RTP does not affect games that aren't compatible with these options.</small>
				</p>
				<table class="games-affected">
					<thead>
						<tr class="sticky tier-sticky">
							<th class="data-header bets">Games Affected</th>
							<th class="data-header paytable">{{ systemCurrencyTool.formatFromAU(1, minorWholeOrFull) }}</th>
							<th class="data-header paytable">{{ systemCurrencyTool.formatFromAU(2, minorWholeOrFull) }}</th>
							<th class="data-header paytable">{{ systemCurrencyTool.formatFromAU(5, minorWholeOrFull) }}</th>
							<th class="data-header paytable">{{ systemCurrencyTool.formatFromAU(10, minorWholeOrFull) }}</th>
							<th class="data-header paytable">{{ systemCurrencyTool.formatFromAU(25, minorWholeOrFull) }}</th>
						</tr>
					</thead>
					<tbody v-if="gamesAllRtpsEnabled.length > 0">
						<tr v-for="(game, index) in gamesAllRtpsEnabled" :key="index">
							<td>{{ game.gameTitle }}</td>
							<td v-for="(rtp, i) in game.rtps" :key="i" class="rtp-preview">
								<span title="Currently active RTP percentage">{{ rtp }}%</span>
								<span
									:class="getRtpChange(game, i) !== 0 ? 'changed-rtp' : 'disable-rtp'"
									v-if="getRtpChange(game, i) != rtp"
									:title="getTitleAttrib(getRtpChange(game, i))"
								>
									({{ getRtpChange(game, i) }}%)
								</span>
							</td>
						</tr>
					</tbody>
					<tbody v-else>
						<tr>
							<td colspan="6">
								<h2 class="no-games">There are no enabled games compatible with bulk RTP assignment.</h2>
							</td>
						</tr>
					</tbody>
				</table>

				<div class="button-group">
					<button type="button" class="btn" v-if="gamesAllRtpsEnabled.length > 0" @click="bulkRtpAssignment()" title="Save Game Options">
						Bulk Save RTPs
					</button>
					<button type="button" class="btn" @click="closeModal()" :title="closeButtonText">{{ closeButtonText }}</button>
				</div>
			</fieldset>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "BulkRTPAssignment",
	inheritAttrs: false,
	props: {
		adminState: Object,
		currentGameTableData: Array,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			minorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			status: Object.assign({}, this.globalStatus),
			body: document.querySelector("body"),
			busyText: "",
			closeButtonText: "Close",
			serverBusy: false,
			fieldsChanged: false,
			denoms: [1, 2, 5, 10, 25],
			rtpOptions: [
				{ text: "0", value: 0 },
				{ text: "90", value: 90 },
				{ text: "92", value: 92 },
				{ text: "94", value: 94 },
				{ text: "96", value: 96 },
				{ text: "98", value: 98 },
			],
			rtpsByDenom: [{ 1: 90 }, { 2: 90 }, { 5: 90 }, { 10: 90 }, { 25: 90 }],
			gamesAllRtpsEnabled: [],
			saveGamesPerDenom: [],
			simpleConfigSaveData: [],
			simpleConfigSaveData: [],
		};
	},
	watch: {
		rtpsByDenom: {
			handler(val, oldVal) {
				this.fieldsChanged = true;
			},
			deep: true,
		},
		fieldsChanged() {
			this.closeButtonText = this.fieldsChanged ? "Cancel" : "Close";
		},
	},
	created() {
		let thisInstance = this;
		this.gamesAllRtpsEnabled = this.currentGameTableData.filter((game) => !game.gameDisabled && game.gameConfigOptions.rtps.length === 5);
		onBeforeUnmount(() => {
			thisInstance.body.onkeydown = null;
		});
	},
	mounted() {
		let thisInstance = this;
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") thisInstance.closeModal();
		};
	},
	methods: {
		getRtpChange(game, i) {
			// the template was getting too messy so, moved the rtp change getter here
			return this.rtpsByDenom[i][game.denominationsAU[i]];
		},
		getTitleAttrib(rtp) {
			// the template was getting too messy so, moved the title attribute logic here
			return rtp !== 0 ? `New RTP percentage for this denom: ${rtp}` : `Warning: Setting RTP to ${rtp} will disable the game for this denom`;
		},
		bulkRtpAssignment() {
			this.busyText = "Mapping RTPs across all compatible games";
			this.serverBusy = true;

			let thisInstance = this;
			this.gamesAllRtpsEnabled.forEach((game) => {
				this.rtpsByDenom.forEach((denom, i) => {
					let rtpObj = Object.entries(denom)[0];
					let saveGame = {
						casinoId: game.casinoId,
						gameTitle: game.gameTitle,
						denomAU: Number(rtpObj[0]),
						rtp: rtpObj[1],
						enabledNumberOfLines: game.enabledNumberOfLines,
						enabledBaseBets: game.enabledBaseBets,
						isSimpleConfig: game.isSimpleConfig,
					};
					thisInstance.saveGamesPerDenom.push(saveGame);
				});
			});

			this.busyText = "";
			this.serverBusy = false;
			this.bulkSaveRtps();
		},
		async bulkSaveRtps() {
			this.busyText = "Bulk Saving RTPs";
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			this.simpleConfigSaveData = this.saveGamesPerDenom;

			try {
				let saveStatus = await sharedScripts.saveSimpleConfig(this);
				if (saveStatus.hasOwnProperty("ok") && !saveStatus.ok) {
					this.busyText = "";
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", saveStatus);
					this.eventBus.emit("closeGameConfigurationModal");
					return false;
				}

				this.status.message = saveStatus.ok ? "All compatible RTPs saved successfully" : saveStatus.message;
				this.status.ok = saveStatus.ok;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("refreshTable");
				this.busyText = "";
				this.serverBusy = false;
				this.closeModal();
			} catch (e) {
				console.error(e);
				this.status.message = "Something went wrong bulk saving RTPs";
				this.status.ok = false;
				this.status.userMustDismiss = true;
				this.busyText = "";
				this.serverBusy = false;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("closeGameConfigurationModal");
			}
		},
		closeModal() {
			this.eventBus.emit("closeGameConfigurationModal");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#bulk-bet-options-loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	background-color: rgb(0 0 0 / 75%);
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
	text-align: center;
	align-content: center;
}

#bulk-set-bet-options {
	position: fixed;
	width: 100vw;
	height: 100vh;
	display: grid;
	align-items: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgb(0 0 0 / 75%);
	color: #fff;
	backdrop-filter: blur(5px);
	z-index: 100;
}

.bulk-bet-options-container {
	overflow: hidden auto;
	width: 90%;
	max-height: calc(100vh - 230px);
	margin: 15px auto;
	min-width: 20em;
	background: #222;
	padding: 15px;
	border-radius: 12px;
	text-align: center;
}

.button-group {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	margin-top: 10px;
}

#rtp-per-denom {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

#rtp-per-denom > div {
	display: flex;
	flex-flow: column nowrap;
}

table.games-affected {
	width: 80%;
	margin: 15px auto;
	padding: 0 15px;
	border-collapse: collapse;
	font-size: 0.8em;
}

table.games-affected td {
	border: 1px #444444 solid;
}

.rtp-preview span {
	cursor: help;
}

.changed-rtp {
	color: #00c4ff;
}

.disable-rtp,
.no-games {
	color: #ff0;
}

.no-games {
	padding: 15px;
}

@media (min-width: 768px) {
	.bulk-bet-options-container {
		width: 80%;
	}
}

@media (min-width: 1024px) {
	.bulk-bet-options-container {
		width: 75%;
	}
}

@media (min-width: 1200px) {
	.bulk-bet-options-container {
		width: 50%;
	}
}
</style>
