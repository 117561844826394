<template>
	<div id="set-contribution">
		<div class="contributions-container">
			<div v-if="!!serverBusy" id="contributions-loading">
				<span class="loading-icon"></span>
				<span class="loading-message">{{ busyText }}</span>
			</div>
			<fieldset>
				<legend>Set Contribution Percentage</legend>
				<div class="potFamilies">
					<h2>{{ casinoGame.gameTitle }}</h2>
					<p class="yellow-text" style="padding: 0 15px; text-align: left">
						Note: The total RTP is the sum of the paytable RTP plus all progressive contributions. A total greater than 100% will result
						in a loss for the casino.
					</p>
					<h3>
						Progressive Family: <span class="potFamilyName">{{ casinoGame.potFamilyName }}</span>
					</h3>
				</div>
				<div class="fields">
					<div v-for="(tier, index) in currentTiers" :key="index">
						<label :for="`tier${index}`">Tier {{ tier.tier }}</label>
						<input
							type="number"
							:id="`tier${tier.tier}`"
							:name="`tier${tier.tier}`"
							class="tiers"
							:title="`tier${tier.tier}`"
							v-model="tier.contributionPercent"
							@input="changed(tier)"
						/>
						<span>%</span>
					</div>
				</div>
				<div class="button-group">
					<button type="button" class="btn" v-if="fieldsChanged" @click="saveProgressives()" title="Save Progressives">
						Save Progressives
					</button>
					<button type="button" class="btn" @click="closeModal()" :title="closeButtonText">{{ closeButtonText }}</button>
				</div>
			</fieldset>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "SetContributionFraction",
	inheritAttrs: false,
	props: {
		adminState: Object,
		casinoGame: Object,
		contributionFraction: Array,
		progressivesSimpleList: Array,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			body: document.querySelector("body"),
			busyText: "",
			closeButtonText: "Close",
			serverBusy: false,
			fieldsChanged: false,
			currentPotFamily: this.casinoGame.potFamilyName || null,
			progressivesCollection: [],
			currentTiers: [],
			familyTierOptions: [],
			newTiers: [],
		};
	},
	watch: {
		currentPotFamily() {
			this.fieldsChanged = true;
			this.mapTiers();
		},
		fieldsChanged() {
			this.closeButtonText = this.fieldsChanged ? "Cancel" : "Close";
		},
	},
	created() {
		this.mapTiers();
		let thisInstance = this;
		onBeforeUnmount(() => {
			thisInstance.body.onkeydown = null;
		});
	},
	mounted() {
		let thisInstance = this;
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") thisInstance.closeModal();
		};
	},
	methods: {
		mapTiers() {
			let thisInstance = this;
			let familyTierOptions = this.progressivesSimpleList.filter((tiers) => tiers.potFamilyName === thisInstance.currentPotFamily);
			let tiers = [];

			const mapTiers = new Map();
			for (const item of familyTierOptions) {
				if (!mapTiers.has(item.tier)) {
					mapTiers.set(item.tier, true);
					tiers.push(item.tier);
				}
			}
			tiers.sort((a, b) => (a > b ? 1 : -1));

			this.currentTiers = [];

			for (let i = 0; i < tiers.length; i++) {
				let tierObj = {};
				tierObj.contributionFraction = this.casinoGame.potFamiliesTiers[i]?.contributionFraction || 0;
				tierObj.contributionPercent = parseFloat((tierObj.contributionFraction * 100).toFixed(4));
				tierObj.tier = this.casinoGame.potFamiliesTiers[i]?.tier || 0;
				this.currentTiers[i] = tierObj;
			}
		},
		changed() {
			this.fieldsChanged = true;
		},
		setProgressivesCollection() {
			this.progressivesCollection = [];
			let error = false;
			let tierFields = document.querySelectorAll(".tiers");
			for (let i = 0; i < tierFields.length; i++) {
				let tierValue = tierFields[i].value;
				if (tierValue >= 0 && tierValue < 100) {
					let progressiveItem = {};
					progressiveItem.casinoId = this.adminState.casinoId;
					progressiveItem.potFamilyName = this.currentPotFamily;
					progressiveItem.gameTitle = this.casinoGame.gameTitle;
					progressiveItem.tier = i;
					progressiveItem.contributionFraction = Math.round((tierValue / 100 + Number.EPSILON) * 1000000) / 1000000;
					this.progressivesCollection.push(progressiveItem);
				} else {
					this.status.ok = false;
					this.status.message = `Tier ${i} must be greater than or equal to 0 and less than 100.`;
					this.eventBus.emit("updateStatus", this.status);
					error = true;
				}
			}
			return error ? false : this.progressivesCollection;
		},
		async saveProgressives() {
			this.busyText = "Saving Progressives";
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let progSuccess = this.setProgressivesCollection();

			if (!progSuccess) {
				this.status.ok = false;
				this.status.message = "Please correct progressive contributions to continue.";
				this.eventBus.emit("updateStatus", this.status);
				this.fieldsChanged = false;
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = this.progressivesCollection;

			let requestUrl = new URL("/api/v1/admin/progressives/simpleconfig", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.busyText = "";
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				this.status.ok = response.ok;
				this.status.code = response.status;
				this.status.message = `Progressives saved successfully for ${this.casinoGame.gameTitle}`;
				let thisInstance = this;
				this.casinoGame.potFamiliesTiers = [];
				this.progressivesCollection.forEach((tier, i) => {
					let thisTier = {
						contributionFraction: tier.contributionFraction,
						potFamilyName: tier.potFamilyName,
						tier: tier.tier,
					};
					this.casinoGame.potFamiliesTiers.push(thisTier);
				});
				this.eventBus.emit("updateCurrentGameTableData", this.casinoGame);
				this.eventBus.emit("updateStatus", this.status);
				this.fieldsChanged = false;
				this.serverBusy = false;
				this.busyText = "";
				this.closeModal(true);
			} catch (e) {
				this.status.code = e;
				this.status.message = "Something went wrong saving progressives";
				this.status.ok = false;
				this.busyText = "";
				this.serverBusy = false;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("closeGameConfigurationModal");
				console.error(e);
				return false;
			}
		},
		closeModal(refreshProgressives = false) {
			this.eventBus.emit("closeGameConfigurationModal", refreshProgressives);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#contributions-loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	background-color: rgb(0 0 0 / 90%);
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
	text-align: center;
	align-content: center;
}

#set-contribution {
	position: fixed;
	width: 100vw;
	height: 100vh;
	display: grid;
	align-items: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgb(0 0 0 / 75%);
	color: #fff;
	backdrop-filter: blur(5px);
	z-index: 100;
}

#set-contribution fieldset {
	display: flex;
	flex-flow: column nowrap;
}

.contributions-container {
	overflow: hidden auto;
	width: 50%;
	margin: 15px auto;
	min-width: 20em;
	background: #222;
	padding: 15px;
	border-radius: 12px;
	text-align: center;
}

.button-group {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	margin-top: 10px;
}
.fields > div {
}

input {
	width: 4em;
}

.potFamilyName {
	color: #00a000;
}
</style>
