<template>
	<div id="user-manager">
		<h1>Casino Administration</h1>
		<div id="tab-ui">
			<ul>
				<li
					title="Casino Basic Details"
					id="CasinoBasicDetails"
					@click="currentTab = 'CasinoBasicDetails'"
					:class="currentTab == 'CasinoBasicDetails' ? 'active' : ''"
				>
					Casino Basic Details
				</li>

				<li
					v-if="adminState?.isSiteAdmin || adminState?.isSysadmin"
					title="Casino Game Configuration"
					id="CasinoGameConfiguration"
					@click="currentTab = 'CasinoGameConfiguration'"
					:class="currentTab == 'CasinoGameConfiguration' ? 'active' : ''"
				>
					Casino Game Configuration
				</li>

				<li
					v-if="adminState?.isSiteAdmin || adminState?.isSysadmin"
					title="Edit Casino Details"
					id="EditCasinoDetails"
					@click="currentTab = 'EditCasinoDetails'"
					:class="currentTab == 'EditCasinoDetails' ? 'active' : ''"
				>
					Edit Casino Details
				</li>

			</ul>
			<div id="tab-contents">
				<keep-alive>
					<component
						v-bind:is="currentTab"
						:adminState="adminState"
						:casinoList="casinoList"
						:isMobile="isMobile"
						:inGameCurrencyTool="inGameCurrencyTool"
						:systemCurrencyTool="systemCurrencyTool"
					/>
				</keep-alive>
			</div>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import CasinoGameConfiguration from "@/components/CasinoGameConfiguration";
import CasinoBasicDetails from "@/components/CasinoBasicDetails";
import EditCasinoDetails from "@/components/EditCasinoDetails";

export default {
	name: "CasinoAdministration",
	components: {
		CasinoGameConfiguration,
		CasinoBasicDetails,
		EditCasinoDetails,
	},
	props: {
		adminState: Object,
		casinoList: Array,
		isMobile: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			setcurrentCompnentWrapper: null,
			offset: null,
			currentTab: "CasinoBasicDetails",
		};
	},
	watch: {
		currentTab(value, oldValue) {
			if (oldValue === "EditCasinoDetails") this.eventBus.emit("resetEditCasinoDetails");
		},
	},
	created() {
		let thisInstance = this;
		onBeforeUnmount(() => {
			let tabs = document.querySelectorAll("#tab-ui > ul li");
			tabs.forEach((tab) => {
				tab.removeEventListener("click", thisInstance.setcurrentCompnentWrapper);
			});
		});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.data-row {
	cursor: pointer;
}

.data-row.selected {
	background-color: #5b88c0;
}

#tab-ui {
	flex: 1 1 auto;
	padding: 0 15px;
}

#tab-ui > div {
	color: #000;
}

#tab-ui ul {
	position: relative;
	display: flex;
	list-style: none;
	margin: 0 auto;
	padding-inline-start: 0;
}

#tab-ui li {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1 1 auto;
	text-align: center;
	padding: 0.5em 1em;
	user-select: none;
	cursor: pointer;
	background-color: #32373f;
	border-width: 1px;
	border-color: #eee;
	border-style: solid;
}

li.active {
	background-color: #6863a3;
}

#tab-contents {
	position: relative;
	margin-bottom: 30px;
	padding: 15px;
	background-color: #ddd;
}

button,
.deselect {
	position: absolute;
	padding: 5px 10px;
	top: 0;
	right: -5px;
	background-color: #5b88c0;
	color: #fff;
	border-radius: 7px;
	box-shadow: 1px 1px 5px rgb(0 0 0 / 30%), inset -1px -1px 5px rgb(0 0 0 / 30%);
	border: none;
	transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}

button {
	position: relative;
	top: 0;
	right: 0;
}

button:hover,
.deselect:hover {
	transform: scale(1.05);
	box-shadow: 2px 2px 8px rgb(0 0 0 / 80%), inset -1px -1px 5px rgb(0 0 0 / 10%);
}

#tab-ui li.active {
	background-color: green;
}

@media (min-width: 768px) {
	.deselect {
		top: 15px;
		right: 15px;
	}
}
</style>
