<template>
	<tr :class="setClassName()" :title="setTitleAttrib()">
		<td class="data-row">{{ game.gameTitle }}</td>

		<td
			v-for="(denom, index) in game.gameConfigOptions.denominationsAU"
			:key="index"
			:title="setDenomTitleAttr(currentRtps[index])"
			:class="setDenomClassName(currentRtps[index])"
			class="data-row edit-lines"
			@click="setCasinoBetOptions(game)"
		>
			{{ currentRtps[index] }}%
		</td>

		<td
			v-if="game?.gameDisabled"
			class="data-row edit-lines game-disabled"
			title="Change Bet Options"
			@click="setCasinoBetOptions(game)"
			colspan="2"
		>
			Game Disabled
		</td>

		<td
			v-if="!game?.gameDisabled"
			class="data-row edit-lines"
			:class="game.enabledNumberOfLines[0] === 0 ? 'ways' : ''"
			title="Change Bet Options"
			@click="setCasinoBetOptions(game)"
		>
			{{ game.enabledNumberOfLines[0] === 0 ? "Ways" : game.enabledNumberOfLines?.join(", ") }}
		</td>
		<td v-if="!game?.gameDisabled" class="data-row edit-lines" title="Change Bet Options" @click="setCasinoBetOptions(game)">
			{{ game.enabledBaseBets?.join(", ") }}
		</td>

		<td v-if="game.potFamilyName" class="data-row edit-contributions" title="Change Contributions" @click="setContributionFraction(game)">
			{{ game.potFamilyName }}
		</td>
		<td v-else class="data-row edit-contributions no-prog" title="N/A">
			{{ "N/A" }}
		</td>
		<td
			v-if="game?.potFamiliesTiers?.length > 0"
			class="data-row edit-contributions"
			title="Change Contributions"
			@click="setContributionFraction(game)"
		>
			<span v-for="(tier, i) in game.potFamiliesTiers" :key="i"
				>{{ parseFloat((tier.contributionFraction * 100).toFixed(4)) }}<span v-if="i < game.potFamiliesTiers.length - 1">, </span>
			</span>
		</td>

		<td v-else class="data-row edit-contributions no-prog" title="Change Contributions">
			{{ "N/A" }}
		</td>

		<td
			:title="flagTotalPercentOverage ? 'Progressive total percent plus RTP percent should be lower than 100%' : 'Progressive total'"
			:class="flagTotalPercentOverage ? 'data-row percentage-overage' : 'data-row'"
		>
			{{ totalPercent || "N/A" }}
		</td>
	</tr>
</template>

<script>
export default {
	name: "ConfigGameInstance",
	props: {
		adminState: Object,
		game: Object,
		gameConfigOptionsList: Array,
	},
	data() {
		return {
			currentRtps: this.game.gameDisabled ? this.getDefaultRtps(this.game) : Array.from(this.game.rtps),
			totalPercent: 0,
			modalConfig: {
				game: {},
				currentModal: "",
			},
			rowClassName: "",
			rowTitle: "",
			flagTotalPercentOverage: false,
			cursor: this.adminState?.isSysadmin ? "pointer" : "text",
		};
	},
	watch: {
		game: {
			handler() {
				this.currentRtps = this.game.gameDisabled ? this.getDefaultRtps(this.game) : Array.from(this.game.rtps);
				if (this.game?.potFamiliesTiers.length > 0) {
					this.setContributionTotals();
					// this.setClassName();
					this.setTitleAttrib();
				}
			},
			deep: true,
		},
	},
	created() {
		this.setContributionTotals();
		// this.setClassName();
		this.setTitleAttrib();
	},
	methods: {
		setDenomTitleAttr(rtp) {
			return rtp === 0 ? "This denom is diabled for this game. Set rtp above 0 to re-enable this denom." : "Change RTP Options";
		},
		setDenomClassName(rtp) {
			if (rtp === 0) {
				return "denom-disabled";
			} else if (rtp + this.totalPercent >= 100) {
				return "percentage-overage";
			}
		},
		setClassName() {
			if (this.game.gameDisabled) {
				return "game-disabled";
			} else {
				return this.game.isSimpleConfig ? "simple" : "not-simple";
			}
		},
		setTitleAttrib() {
			if (this.game.gameDisabled) {
				return "Game is disabled";
			} else {
				return this.game.isSimpleConfig ? "" : "Not simple config";
			}
		},
		setContributionTotals() {
			let thisInstance = this;
			this.totalPercent = 0;
			if (!this.game.potFamiliesTiers) return;
			this.game.potFamiliesTiers.forEach((tier) => {
				thisInstance.totalPercent += tier.contributionFraction;
			});
			this.totalPercent = parseFloat((this.totalPercent * 100).toFixed(4));
			let percentOver = (rtp) => rtp + thisInstance.totalPercent >= 100;
			this.flagTotalPercentOverage = this.currentRtps.some(percentOver);
		},
		setCasinoBetOptions(game) {
			this.modalConfig.game = game;
			this.modalConfig.currentModal = "SetGameOptions";
			this.eventBus.emit("setGameConfigurationModal", this.modalConfig);
		},
		setContributionFraction(game) {
			this.modalConfig.game = game;
			this.modalConfig.currentModal = "SetContributionFraction";
			this.eventBus.emit("setGameConfigurationModal", this.modalConfig);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td {
	border: 1px #000 solid;
	padding: 5px;
}

tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.edit-contributions:not(.no-prog),
.edit-lines:not(.no-prog) {
	cursor: v-bind("cursor");
	transition: background-color 0.2s;
}

.edit-contributions:not(.no-prog):hover,
.edit-lines:not(.no-prog):hover {
	background-color: #ff0;
}

.not-simple,
.percentage-overage {
	color: #f00;
	font-weight: bold;
	cursor: help;
}

.percentage-overage {
	background-color: #000;
	white-space: nowrap;
}

.percentage-overage.edit-lines:hover {
	background-color: #00f;
}

.not-simple .data-row:first-child::after {
	content: "\a Not simple config";
}

.data-row.percentage-overage::after {
	content: " !";
}

tr.game-disabled {
	background: #5c5c5c !important;
	color: #939393;
	cursor: help;
}

.denom-disabled {
	background-color: #deb887;
	color: #4b0082;
	font-weight: bold;
	text-decoration: line-through;
	text-decoration-thickness: 2px;
	text-decoration-color: red;
}

td.data-row.game-disabled {
	background-color: #fff;
	color: red;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	border: 3px red solid;
}

.ways {
	text-transform: uppercase;
	text-align: center;
}
</style>
