<template>
	<table class="game-config-list">
		<tbody>
			<tr>
				<th class="data-header title">Title</th>
				<td class="data-row title" colspan="2">{{ game.gameTitle }}</td>
			</tr>
			<tr>
				<th class="data-header paytable" rowspan="6">RTP</th>
			</tr>
			<tr
				v-for="(rtp, index) in currentRtps"
				:key="index"
				:title="setDenomTitleAttr(rtp)"
				:class="setDenomClassName(rtp)"
				class="data-row edit-lines"
			>
				<th class="data-header paytable">{{ rtpAmounts[index] }}</th>
				<td @click="setCasinoBetOptions(game)">{{ rtp }}%</td>
			</tr>
			<tr>
				<th class="data-header bets" rowspan="3">Bets</th>
			</tr>
			<tr>
				<th class="data-header bets"># Lines</th>
				<td
					v-if="game?.gameDisabled"
					class="data-row edit-lines game-disabled"
					title="Change Bet Options"
					rowspan="2"
					@click="setCasinoBetOptions(game)"
				>
					Game Disabled
				</td>
				<td
					v-if="!game?.gameDisabled"
					class="data-row edit-lines"
					:class="game.enabledNumberOfLines[0] === 0 ? 'ways' : ''"
					title="Change Bet Options"
					@click="setCasinoBetOptions(game)"
				>
					{{ game.enabledNumberOfLines[0] === 0 ? "Ways" : game.enabledNumberOfLines?.join(", ") }}
				</td>
			</tr>
			<tr>
				<th class="data-header bets">BPL</th>
				<td v-if="!game?.gameDisabled" class="data-row edit-lines" title="Bet Options" @click="setCasinoBetOptions(game)">
					{{ game.enabledBaseBets?.join(", ") }}
				</td>
			</tr>
			<tr>
				<th class="data-header progressives" rowspan="4">prog.</th>
			</tr>
			<tr>
				<th class="data-header progressives">Family</th>
				<td
					class="data-row edit-contributions"
					title="Progressives Family"
					@click="game.potFamilyName ? setContributionFraction(game) : null"
				>
					{{ game.potFamilyName || "N/A" }}
				</td>
			</tr>
			<tr>
				<th class="data-header progressives">Contrib. %</th>
				<td
					v-if="game?.potFamiliesTiers?.length > 0"
					class="data-row edit-contributions"
					title="Change Contributions"
					@click="setContributionFraction(game)"
				>
					<span v-for="(tier, i) in game.potFamiliesTiers" :key="i"
						>{{ parseFloat((tier.contributionFraction * 100).toFixed(4)) }}<span v-if="i < game.potFamiliesTiers.length - 1">, </span>
					</span>
				</td>
				<td v-else class="data-row edit-contributions no-prog" title="Change Contributions">
					{{ "N/A" }}
				</td>
			</tr>
			<tr>
				<th class="data-header progressives">Total %</th>
				<td
					:title="flagTotalPercentOverage ? 'Progressive total percent plus RTP percent should be lower than 100%' : 'Progressive total'"
					:class="flagTotalPercentOverage ? 'data-row percentage-overage' : 'data-row'"
					@click="totalPercent ? setContributionFraction(game) : null"
				>
					{{ totalPercent || "N/A" }}
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: "ConfigGameInstanceMobile",
	props: {
		adminState: Object,
		game: Object,
		gameConfigOptionsList: Array,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			minorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			rtpAmounts: ["$0.01", "$0.02", "$0.05", "$0.10", "$0.25"],
			currentRtps: this.game.gameDisabled ? this.getDefaultRtps(this.game) : Array.from(this.game.rtps),
			totalPercent: 0,
			modalConfig: {
				game: {},
				currentModal: "",
			},
			flagTotalPercentOverage: false,
			cursor: this.adminState?.isSysadmin ? "pointer" : "text",
		};
	},
	watch: {
		game: {
			handler() {
				this.currentRtps = this.game.gameDisabled ? this.getDefaultRtps(this.game) : Array.from(this.game.rtps);
				if (this.game?.potFamiliesTiers.length > 0) {
					this.setContributionTotals();
				}
			},
			deep: true,
		},
	},
	created() {
		this.setContributionTotals();
	},
	methods: {
		setDenomTitleAttr(rtp) {
			return rtp === 0 ? "This denom is diabled for this game. Set rtp above 0 to re-enable this denom." : "Change RTP Options";
		},
		setDenomClassName(rtp) {
			if (rtp === 0) {
				return "denom-disabled";
			} else if (rtp + this.totalPercent >= 100) {
				return "percentage-overage";
			}
		},
		setContributionTotals() {
			let thisInstance = this;
			this.totalPercent = 0;
			if (!this.game.potFamiliesTiers) return;
			this.game.potFamiliesTiers.forEach((tier) => {
				thisInstance.totalPercent += tier.contributionFraction;
			});
			this.totalPercent = parseFloat((this.totalPercent * 100).toFixed(4));
			let percentOver = (rtp) => rtp + thisInstance.totalPercent >= 100;
			this.flagTotalPercentOverage = this.currentRtps.some(percentOver);
		},
		setCasinoBetOptions(game) {
			this.modalConfig.game = game;
			this.modalConfig.currentModal = "SetGameOptions";
			this.eventBus.emit("setGameConfigurationModal", this.modalConfig);
		},
		setContributionFraction(game) {
			this.modalConfig.game = game;
			this.modalConfig.currentModal = "SetContributionFraction";
			this.eventBus.emit("setGameConfigurationModal", this.modalConfig);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.game-config-list {
	width: 100%;
	margin-bottom: 4em;
}

.game-config-list td {
	width: 50%;
}

td,
th {
	border: 1px #000 solid;
	padding: 5px;
}

.edit-contributions:not(.no-prog),
.edit-lines:not(.no-prog) {
	cursor: v-bind("cursor");
	transition: background-color 0.2s;
}

.edit-contributions:not(.no-prog):hover,
.edit-lines:not(.no-prog):hover {
	background-color: #ff0;
}

td[title^="Progressives"] {
	overflow-wrap: anywhere;
}

.not-simple,
.percentage-overage {
	color: #f00;
	font-weight: bold;
	cursor: help;
}

.percentage-overage {
	background-color: #000;
	white-space: nowrap;
}

.percentage-overage.edit-lines:hover {
	background-color: #00f;
}

.not-simple .data-row:first-child::after {
	content: "\a Not simple config";
}

.data-row.percentage-overage::after {
	content: " !";
}

tr.game-disabled {
	background: #5c5c5c !important;
	color: #939393;
	cursor: help;
}

.denom-disabled {
	background-color: #deb887;
	color: #4b0082;
	font-weight: bold;
	text-decoration: line-through;
	text-decoration-thickness: 2px;
	text-decoration-color: red;
}

td.data-row.game-disabled {
	background-color: #fff;
	color: red;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	border: 3px red solid;
}

.ways {
	text-transform: uppercase;
	text-align: center;
}

.rotate-vert {
	transform: rotate(-90deg);
}

.title {
	background-color: #000;
	color: #fff;
}

td.title {
	text-align: center;
}

.paytable {
	background-color: #00a6ff;
}

.bets {
	background-color: #00ffbf;
}

.progressives {
	background-color: #ffa000;
}
</style>
